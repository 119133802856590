/*
 |--------------------------------------------------------------------------
 | Vue.js
 |--------------------------------------------------------------------------
 */
import Vue from 'vue'

/*
 |--------------------------------------------------------------------------
 | Axios HTTP library (AJAX)
 |--------------------------------------------------------------------------
 */
window.request = require('axios')

window.request.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
}

/*
|--------------------------------------------------------------------------
| Font Awesome
|--------------------------------------------------------------------------
*/
require('./lib/vendor/font-awesome/fontawesome.min')
require('./lib/vendor/font-awesome/solid')
require('./lib/vendor/font-awesome/regular')
require('./lib/vendor/font-awesome/light')

/*
|--------------------------------------------------------------------------
| Home Page Template Dependencies
|--------------------------------------------------------------------------
*/
// require('./lib/vendor/home_page/modernizr.custom')
require('bootstrap')
import jQuery from 'jquery'
window.$ = jQuery
window.jQuery = jQuery
require('jquery-ui')
require('popper.js')
require('./lib/vendor/home_page/countdown')
require('./lib/vendor/home_page/html5shiv')
require('./lib/vendor/home_page/html5shiv-printshiv')
require('./lib/vendor/home_page/jquery.counterup.min')
require('./lib/vendor/home_page/jquery.fancybox.min')
require('./lib/vendor/home_page/jquery.scrollUp')
require('./lib/vendor/home_page/jquery.slicknav.min')
require('./lib/vendor/home_page/jquery.waypoints.min')
require('./lib/vendor/home_page/shuffle.min')
require('./lib/vendor/home_page/theme')

/*
 |--------------------------------------------------------------------------
 | Vue Components
 |--------------------------------------------------------------------------
 */
import {
    Home,
} from './guest_components/index'

/*
 |--------------------------------------------------------------------------
 | Vue Router
 |--------------------------------------------------------------------------
 */
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let router = new VueRouter({
    linkActiveClass: 'is-active',
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home
        },
    ],
})

import Guest from './Guest.vue'

/*
|--------------------------------------------------------------------------
| SaSS
|--------------------------------------------------------------------------
*/
import './sass/guest.scss'

/*
 |--------------------------------------------------------------------------
 | Main Vue instance
 |--------------------------------------------------------------------------
 */
new Vue({
    router,
    render: h => h(Guest)
}).$mount('#app')