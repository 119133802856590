<template>
    <div :class="containterClasses" style="font-size: 16px;">
        <div :class="spinnerClass" :style="spinnerStyle"></div>
    </div>
</template>

<script>
    export default {
        props: {
            is_box: {
                type: Boolean,
                default: true
            },
            border: {
                type: Boolean,
                default: true
            },
            // To be removed
            no_border: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            containterClasses() {
                let classes = null;

                if (this.is_box && this.no_border && this.border) classes = 'content-box';

                return classes;
            },
            spinnerStyle() {
                return this.is_box ? 'height: 100px; margin-bottom: 10px;' : null;
            },
            spinnerClass() {
                return this.is_box ? 'crabbly_spinner_full' : 'crabbly_spinner';
            }
        }
    };
</script>