<template>
	<section id="vue-calendar">
        <div id="header">
            <div>
                {{ selectedMonth }}
                <button class="pointer" @click="previousMonth">-</button> 
                <button class="pointer" @click="nextMonth">+</button>
            </div>
        </div>

        <div id="day-bar">
            <div v-for="(weekday, index) in weekdays" :key="index">
                {{ weekday }}
            </div>
        </div>

        <div id="calendar">
            <div v-for="(week, index) in weeks" :key="index" class="calendar-week">
                <div v-for="(day, index_day) in week" :key="index + '_' + index_day" class="day" :class="dayClasses(day)" @click="selected_day = day.timestamp">
                    <div>{{ day.date }}</div> 
                    <ul class="event-list">
                        <li @click="$emit('itemSelected', event)" v-for="(event, index) in day.events" :key="index">
                            <span class="pointer hint--top" :aria-label="buildTooltipText(event)">
                                {{ buildText(event) }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default() {
				return []
			}
		},
		dateField: {
			type: String,
			default: 'date'
		},
        tooltip: {
			type: Boolean,
			default: false
        },
		tooltipText: {
			type: String,
			default: null
		},
        displayText: {
            type: String,
            default: null
        }
	},
	data() {
		return {
			pageDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, new Date().getHours(), new Date().getMinutes()).getTime(),
            selected_day: null,
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		}
	},
	computed: {
		blankDays () {
            const d = new Date(this.pageDate);
            let dObj = new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes())
            return dObj.getDay()
        },
        selectedMonth() {
            let date = new Date(this.pageDate);
            return this.months[date.getMonth()] + ' ' + date.getFullYear();
        },
        weeks() {
            let date = new Date(this.pageDate);
            let weeksCount = (this.daysInMonth(date.getFullYear(), date.getMonth()) + this.blankDays) / 7;
            if ((this.daysInMonth(date.getFullYear(), date.getMonth()) + this.blankDays) % 7 > 0) weeksCount++;

            weeksCount = parseInt(weeksCount);

            // set up a new date object to the beginning of the current 'page'
            let dObj = new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes())

            dObj.setDate(dObj.getDate() - this.blankDays);

            let weeks = [];

            for (let i = 0; i < weeksCount; i++) {
                let days = [];

                for (let j = 0; j < 7; j++) {
                    days.push({
                        date: dObj.getDate(),
                        timestamp: dObj.getTime(),
                        isSelected: dObj.toDateString() === (new Date(this.selected_day)).toDateString(),
                        isToday: dObj.toDateString() === (new Date()).toDateString(),
                        events: this.getItems(dObj),
                    });
                    dObj.setDate(dObj.getDate() + 1);
                }
                weeks.push(days);
            }

            return weeks;
        },
	},
    methods: {
        daysInMonth (year, month) {
            return /8|3|5|10/.test(month) ? 30 : month === 1 ? (!(year % 4) && year % 100) || !(year % 400) ? 29 : 28 : 31
        },
        nextMonth() {
            let date = new Date(this.pageDate);
            date = new Date(date.getFullYear(), date.getMonth() + 1, 1, date.getHours(), date.getMinutes());

            this.pageDate = date.getTime();

            this.$emit('change', date);
        },
        previousMonth() {
            let date = new Date(this.pageDate);
            date = new Date(date.getFullYear(), date.getMonth() - 1, 1, date.getHours(), date.getMinutes())

            this.pageDate = date.getTime();

            this.$emit('change', date);
        },
        dayClasses (day) {
            return {
                'today': day.isToday,
                'active': day.isSelected,
                'disabled': day.isDisabled,
                'not-current-month': (new Date(day.timestamp).getMonth() !== new Date(this.pageDate).getMonth())
            }
        },
        getItems(dObj) {
            return this.items.filter(item => (new Date(item[this.dateField]).toDateString() === dObj.toDateString()));
        },
        buildText(event) {
            if (this.displayText) {
                let text = this.displayText;

                let fields = this.displayText.match(/=[a-z_]+=/g);

                fields.forEach(field => {
                    text = text.replace(field, event[field.replace(/=/g, '')]);
                })

                return text;
            }

            return '';
        },
        buildTooltipText(event) {
            if (this.tooltipText) {
                let text = this.tooltipText;

                let fields = this.tooltipText.match(/=[a-z_]+=/g);

                fields.forEach(field => {
                    text = text.replace(field, event[field.replace(/=/g, '')]);
                })

                return text;
            }

            return '';
        }
    },
}
</script>