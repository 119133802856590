<template>
    <div :name="name" class="infinite-scroll" :class="inner_class">
        <slot name="grid-container"></slot>

        <!-- Spinner -->
        <spinner :border="false" class="mgt20" v-show="fetching"></spinner>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Array,
                defalt: []
            },
            callback_method: {
                type: Function,
                default: () => {}
            },
            target_element: {
                type: String,
                default: null
            },
            pagination: {
                type: Object,
                default() {
                    return {
                        total: 0,
                        per_page: 12,
                        current_page: 1,
                        from: 0,
                        to: 0,
                        total_pages: 1
                    }
                }
            },
            is_active: {
                type: Boolean,
                default: false,
            },
            name: {
                type: String,
                default: 'infinite-scroll'
            },
            inner_class: {
                type: String,
                default: null
            },
            has_scroll: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                fetching: false,
                max_distance: 200,
            }
        },
        mounted() {
            this.initInfinitScroll();
        },
        methods: {
            infiniteScroll() {
                let element = this.findElement();

                if (this.pagination.current_page >= this.pagination.total_pages || !this.is_active) {
                    return;
                }

                let distanceBottom = element.scrollHeight - element.clientHeight - element.scrollTop;
                let page = this.pagination.current_page + 1;

                if (distanceBottom <= this.max_distance && !this.fetching) {
                    this.removeInfiniteScroll();
                    this.fetching = true;
                    this.callback_method(page)
                        .then((response) => {
                            this.changePagination(response.data.meta.pagination);
                            response.data.data.forEach(row => this.data.push(row));
                            this.fetching = false;
                            this.$emit('change', page);
                            this.initInfinitScroll();
                        }).catch(error => {
                            this.fetching = false;
                            app.handleError(error);
                        });
                    
                }
            },
            initInfinitScroll() {
                //let element = this.target_element ? document.getElementById(this.target_element) : document;
                let element = this.has_scroll ? document.querySelector('div[name=' + this.name + ']') : document;
                element.addEventListener('scroll', this.infiniteScroll);
            },
            findElement() {
                //return this.target_element ? document.getElementById(this.target_element) : document.documentElement;
                return this.has_scroll ? document.querySelector('div[name=' + this.name + ']') : document.documentElement;
            },
            changePagination(pagination) {
                this.pagination.total = pagination.total;
                this.pagination.to = pagination.to;
                this.pagination.from = pagination.from;
                this.pagination.per_page = pagination.per_page;
                this.pagination.current_page = pagination.current_page;
                this.pagination.total_page = pagination.total_pages;
            },
            removeInfiniteScroll() {
                //let element = this.target_element ? document.getElementById(this.target_element) : document;
                let element = this.has_scroll ? document.querySelector('div[name=' + this.name + ']') : document;
                element.removeEventListener('scroll', this.infiniteScroll);
            }
        },
        destroyed() {
            this.removeInfiniteScroll();
        }
    }
</script>
