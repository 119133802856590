<template>
    <div class="pagination" :class="size">
        <ul class="pagination-list" :class="{'hidden': !pagination.total}">
            <li><a class="pagination-previous" @click="prev" :disabled="!hasPrev"><i class="fa fa-angle-left"></i></a></li>

            <!-- First -->
            <li v-if="hasFirst"><a class="pagination-link" @click="first">1</a></li>
            <li v-if="hasFirstEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>

            <!-- Pages -->
            <li v-for="page in pagesInRange" :key="page.id">
                <a class="pagination-link" @click="page.click" :class="{ 'is-current': page.isCurrent }">
                    {{ page.number }}
                </a>
            </li>

            <!-- Last -->
            <li v-if="hasLastEllipsis"><span class="pagination-ellipsis">&hellip;</span></li>
            <li v-if="hasLast"><a class="pagination-link" @click="last">{{ pageCount }}</a></li>

            <li><a class="pagination-next" @click="next" :disabled="!hasNext"><i class="fa fa-angle-right"></i></a></li>
        </ul>

        <span v-show="pagination.from" class="pagination-summary">
            Displaying {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} {{ items }}.
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            pagination: {
                type: Object,
                default() {
                    return {
                        total: 0,
                        per_page: 12,
                        current_page: 1,
                        from: 0,
                        to: 0,
                        total_pages: 1
                    }
                }
            },
            size: String,
            items: {
                type: String,
                default: 'items'
            },
        },
        computed: {
            pageCount() {
                return Math.ceil(this.pagination.total / this.pagination.per_page);
            },
            firstItem() {
                const firstItem = this.pagination.current_page * this.pagination.per_page - this.pagination.per_page + 1;
                return firstItem >= 0 ? firstItem : 0
            },
            hasPrev() {
                return this.pagination.current_page > 1;
            },
            hasFirst() {
                return this.pagination.current_page > 3;
            },
            hasFirstEllipsis() {
                return this.pagination.current_page >= 5;
            },
            hasLast() {
                return this.pagination.current_page < this.pageCount - 2;
            },
            hasLastEllipsis() {
                return this.pagination.current_page < this.pageCount - 2 && this.pagination.current_page <= this.pageCount - 4;
            },
            hasNext() {
                return this.pagination.current_page < this.pageCount;
            },
            pagesInRange() {
                const left = Math.max(1, this.pagination.current_page - 1);
                const right = Math.min(this.pagination.current_page + 1, this.pageCount);

                const pages = [];
                for (let i = left; i <= right; i++) {
                    pages.push({
                        number: i,
                        isCurrent: this.pagination.current_page === i,
                        click: () => this.$emit('change', i)
                    });
                }
                return pages;
            }
        },
        watch: {
            pageCount(value) {
                if (this.pagination.current_page > value) this.last();
            }
        },
        methods: {
            prev() {
                if (this.hasPrev) this.$emit('change', this.pagination.current_page - 1);
            },
            first() {
                this.$emit('change', 1);
            },
            last() {
                if (this.hasLast) this.$emit('change', this.pageCount);
            },
            next() {
                if (this.hasNext) this.$emit('change', this.pagination.current_page + 1);
            }
        }
    }
</script>
