// -------------------
//   js index
/* =================== */
/*  
    ## Preloader
    ## Wordpress Friendly Menu
    ## SlickNav menu Activation
    ## Sticky
    ## Scroll-up
    ## Counter
    ## Smooth scroll
*/
// -----------------------------
"use strict";

/*---------------------
preloader
--------------------- */

$(window).on('load', function() {
    $('#preloader').fadeOut('slow', function() {
        $(this).remove();
    });
});

/*---------------------
 Wordpress Friendly Menu
 --------------------- */
$(window).on('resize', function() {
    var wWidth = $(this).width();

    var selectedMenu = $('.main-menu'); // .main-menu  nav  ul li a (this is the structur)
    if (wWidth > 991) {
        selectedMenu.addClass('menu-activated');
        $('.menu-activated >nav >ul >li ul').addClass('sub-menu');
        $('.menu-activated >nav >ul >li ul li:has(ul)').addClass('menu-item-has-children');
        $('.menu-activated >nav >ul >li:has(ul)').addClass('menu-item-has-children has-submenu');
        $('.prc-anim').addClass('prc-area');

    } else {
        $('.menu-activated >nav >ul >li ul').removeClass('sub-menu');
        $('.menu-activated >nav >ul li:has(ul)').removeClass('menu-item-has-children');
        $('.menu-activated >nav >ul >li:has(ul)').removeClass('menu-item-has-children has-submenu');
        selectedMenu.removeClass('menu-activated');
        $('.prc-anim').removeClass('prc-area');

        $('.feature-item-area .left-feature').removeClass('left-feature');
    }

}).resize();


/*-----------------
Sticky
-----------------*/
$(window).on('scroll', function() {
    if ($(window).scrollTop() > 85) {
        $('header').addClass('navbar-fixed-top');
    } else {
        $('header').removeClass('navbar-fixed-top');
    }
});

/*-----------------
Scroll-up
-----------------*/
$.scrollUp({
    scrollText: '<i class="fa fa-arrow-up" aria-hidden="true"></i>',
    easingType: 'linear',
    scrollSpeed: 1500,
    animation: 'fade'
});


/*---------------------
Smooth scroll
--------------------- */
$('.smoothscroll').on('click', function(e) {
    e.preventDefault();
    var target = this.hash;

    $('html, body').stop().animate({
        'scrollTop': $(target).offset().top - 80
    }, 1200);
});