<template>
    <section id="contact">
        <h4 style="color: #666;">Contact Us</h4>
<!--        <input type="text" class="form-control" placeholder="Full Name" v-model="contact.name">-->
<!--        <input type="email" class="form-control" placeholder="Email Address" v-model="contact.email">-->
<!--        <textarea class="form-control" placeholder="Message" v-model="contact.message"></textarea>-->
        <button class="submit-button" @click="mailTo">
            Send Message
            <span class="icon">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
            </span>
        </button>
    </section>
</template>

<script>
export default {
    data() {
        return {
            contact: {
                name: null,
                email: null,
                phone: null,
                message: null
            },
            processing: false,
            submitted: false,
        }
    },
    methods: {
        mailTo() {
          window.open("mailto:contato@craftsoftware.dev");
        },
        submit() {
            this.processing = true

            return new Promise((resolve, reject) => {
                request.post(app.api + 'contact', this.contact)
                    .then(response => {
                        app.handleResponse(response)
                        this.submitted = true
                        this.processing = false
                        resolve()
                    }).catch(error => {
                        app.handleError(error)
                        this.processing = false
                        reject()
                    })
            })
        }
    }
}
</script>
