import Vue from 'vue'
/*
|--------------------------------------------------------------------------
| Home Components
|--------------------------------------------------------------------------
*/
import {
    Home,
} from './home/index'


/*
 |--------------------------------------------------------------------------
 | Global App Components
 |--------------------------------------------------------------------------
 */
import { Spinner, Logo } from '../components/misc/index'
import { Pagination } from '../components/grid/index'

/*
 |--------------------------------------------------------------------------
 | Create Global Components
 |--------------------------------------------------------------------------
 */
Vue.component('spinner', Spinner)
Vue.component('app-logo', Logo)
Vue.component('pagination', Pagination)

/*
 |--------------------------------------------------------------------------
 | Export modules
 |--------------------------------------------------------------------------
 */
export {
    Home,
}