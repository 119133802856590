<template>
    <section>
        <div class="container-fluid gtco-feature">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="cover">
                            <div class="card">
                                <svg
                                        class="back-bg"
                                        width="100%" viewBox="0 0 900 700" style="position:absolute; z-index: -1">
                                    <defs>
                                        <linearGradient id="PSgrad_01" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                            <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1"/>
                                            <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1"/>
                                        </linearGradient>
                                    </defs>
                                    <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_01)"
                                          d="M616.656,2.494 L89.351,98.948 C19.867,111.658 -16.508,176.639 7.408,240.130 L122.755,546.348 C141.761,596.806 203.597,623.407 259.843,609.597 L697.535,502.126 C748.221,489.680 783.967,441.432 777.751,392.742 L739.837,95.775 C732.096,35.145 677.715,-8.675 616.656,2.494 Z"/>
                                </svg>
                                <!-- *************-->

                                <svg width="100%" viewBox="0 0 700 500">
                                    <clipPath id="clip-path">
                                        <path d="M89.479,0.180 L512.635,25.932 C568.395,29.326 603.115,76.927 590.357,129.078 L528.827,380.603 C518.688,422.048 472.661,448.814 427.190,443.300 L73.350,400.391 C32.374,395.422 -0.267,360.907 -0.002,322.064 L1.609,85.154 C1.938,36.786 40.481,-2.801 89.479,0.180 Z"></path>
                                    </clipPath>
                                    <!-- xlink:href for modern browsers, src for IE8- -->
                                    <image clip-path="url(#clip-path)" xlink:href="/images/learn-img.jpg" width="100%"
                                           height="465" class="svg__image"></image>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <h2> We are a Creative Software Development Team</h2>
                        <p>We develop, evolve and support custom web apps focused on UX and business results, with engineering and technology that make them robust and scalable.</p>
                        <p>Our dedicated team will be with you from business requirement analysis to successful deployment of the software solution.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid gtco-features" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <h2>Build the product of your dream from scratch</h2>
                        <p>We undertake every aspect of your software initiative: from the planning and architecture, to design and UI, integration and implementation, testing and quality assurance to training and deployment.</p>
                    </div>
                    <div class="col-lg-8">
                        <svg id="bg-services"
                             width="100%"
                             viewBox="0 0 1000 800">
                            <defs>
                                <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                    <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1"/>
                                    <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1"/>
                                </linearGradient>
                            </defs>
                            <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_02)"
                                  d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"/>
                        </svg>
                        <div class="row">
                            <div class="col">
                                <div class="card text-center">
                                    <div class="oval"><img class="card-img-top" src="/images/web-development.png" alt=""></div>
                                    <div class="card-body">
                                        <h3 class="card-title">Custom Software Development</h3>
                                        <p class="card-text">Our experienced software engineers can bring your most wild ideas to the market.</p>
                                    </div>
                                </div>
                                <div class="card text-center">
                                    <div class="oval"><img class="card-img-top" src="/images/architecture.png" alt=""></div>
                                    <div class="card-body">
                                        <h3 class="card-title">Architecture</h3>
                                        <p class="card-text">We design the application architecture even before we write any code, always delivering scalable software using industry best practices.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card text-center">
                                    <div class="oval"><img class="card-img-top" src="/images/agile.png" alt=""></div>
                                    <div class="card-body">
                                        <h3 class="card-title">Agile Development</h3>
                                        <p class="card-text">Scheduled features delivery with continued integration deployments.</p>
                                    </div>
                                </div>
                                <div class="card text-center">
                                    <div class="oval"><img class="card-img-top" src="/images/graphics-design.png" alt=""></div>
                                    <div class="card-body">
                                        <h3 class="card-title">UX Design</h3>
                                        <p class="card-text">Effective user interfaces delivered for the best user experience. Yes, we know UX!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid gtco-numbers-block">
            <div class="container">
                <svg width="100%" viewBox="0 0 1600 400">
                    <defs>
                        <linearGradient id="PSgrad_03" x1="80.279%" x2="0%"  y2="0%">
                            <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1" />
                            <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1" />
                        </linearGradient>

                    </defs>
                    <!-- <clipPath id="clip-path3"></clipPath> -->

                    <path fill-rule="evenodd"  fill="url(#PSgrad_03)"
                          d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"></path>

                    <clipPath id="ctm" fill="none">
                        <path
                                d="M98.891,386.002 L1527.942,380.805 C1581.806,380.610 1599.093,335.367 1570.005,284.353 L1480.254,126.948 C1458.704,89.153 1408.314,59.820 1366.025,57.550 L298.504,0.261 C238.784,-2.944 166.619,25.419 138.312,70.265 L16.944,262.546 C-24.214,327.750 12.103,386.317 98.891,386.002 Z"></path>
                    </clipPath>

                    <!-- xlink:href for modern browsers, src for IE8- -->
                    <image  clip-path="url(#ctm)" xlink:href="/images/word-map.png" height="800px" width="100%" class="svg__image">

                    </image>

                </svg>
                <div class="row">
                    <div class="col-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Vue</h5>
                                <p class="card-text">Front-end interfaces with Vue and its ecosystem</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">React</h5>
                                <p class="card-text">Web and Mobile applications with React</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Laravel</h5>
                                <p class="card-text">We talk PHP and we love Laravel</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Go</h5>
                                <p class="card-text">Blazing fast services with Golang</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid gtco-features-list">
            <div class="container">
                <div class="row">
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/quality-results.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Effective Delivery</h5>
                            Based on the budget and timeframe limitations, assisting analysts determine the critical features to be implemented in the minimal viable product.
                        </div>
                    </div>
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/analytics.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Product Engineering</h5>
                            Build amazing software products at lightning speed with our full lifecycle product engineering services.
                        </div>
                    </div>
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/affordable-pricing.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Affordable Pricing</h5>
                            Our apps are designed to help you increase efficiency & productivity while also reducing costs.
                        </div>
                    </div>
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/easy-to-use.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Craft Development</h5>
                            We analyze your operation in order to determine the best way to convert your manual procedures into an automatic, time-saving software solution.
                        </div>
                    </div>
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/free-support.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Support</h5>
                            From face to face meetings and customized user training, we make sure that our clients are fully capable to get the most out of their crafted applications.
                        </div>
                    </div>
                    <div class="media col-md-6 col-lg-4">
                        <div class="oval mr-4"><img class="align-self-start" src="/images/effectively-increase.png" alt=""></div>
                        <div class="media-body">
                            <h5 class="mb-0">Refactoring</h5>
                            Transform your legacy software into fully-modern applications with our modernization strategies.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                features: [
                    {
                        title: 'Revolutionary Automation',
                        description: 'Save time automating your daily brokerage tasks using our cutting edge technology.',
                        icon: 'fal fa-tachometer-alt'
                    },
                    {
                        title: 'Transaction Management',
                        description: 'Scale and manage a large number of transactions with an easy and efficient work flow made for Brokers.',
                        icon: 'fal fa-cogs'
                    },
                    {
                        title: 'Commission Disbursement',
                        description: 'Automaticaly calculates agent commissions and create transaction statements.',
                        icon: 'fal fa-chart-pie'
                    },
                    {
                        title: 'Unlimited Users',
                        description: 'Easily create and manage user accounts, including agent profiles and commission plans.',
                        icon: 'fal fa-id-card'
                    },
                    {
                        title: 'Document Manipulation',
                        description: 'Manage, merge and manipulate transaction documentations with the most easy to use to use interface.',
                        icon: 'fal fa-file-pdf'
                    },
                    {
                        title: 'Reports',
                        description: 'Take advantage of data and analytics-powered reports to view past, current and projected internal office trends.',
                        icon: 'fal fa-chart-bar'
                    },
                    {
                        title: 'Forms Library',
                        description: 'Create your own brokerage forms library, available for agents to use.',
                        icon: 'fal fa-file-alt'
                    },
                    {
                        title: 'On The Cloud',
                        description: 'Access your data from any device, anywhere, anytime, in a secure platform.',
                        icon: 'fal fa-cloud'
                    },
                    {
                        title: 'Email Automation',
                        description: 'Automated emails sent to agents with transaction updates and commission disbursement statements.',
                        icon: 'fal fa-paper-plane'
                    }
                ]
            }
        }
    }
</script>