<template>
    <section>
        <!-- Header and Banner -->
        <header-banner></header-banner>

        <!-- Service -->
        <services id="services"></services>

        <!-- Footer -->
        <home-footer></home-footer>
    </section>
</template>

<script>
    import HeaderBanner from './HeaderBanner'
    import HomeFooter from './Footer'
    import Services from './Services'

    export default {
        components: {
            HomeFooter,
            Services,
            HeaderBanner,
        }
    }
</script>