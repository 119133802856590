<template>
    <!--<svg :class="preset + '-logo'"-->
         <!--xmlns="http://www.w3.org/2000/svg"-->
         <!--viewBox="0 0 685.55 122.68">-->
        <!--<title>CraftDocs</title>-->
        <!--<path :class="preset + '-logo&#45;&#45;icon-a'" d="M596.08,654.76h-2.25c-31.26,0-56.42-5.53-75.26-12l-46.14,18.6H594.88l70.91-31.26h-18Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;icon-b'" d="M525.34,593c13.33,15.57,36.44,35.42,70.81,41.52l.93.17,77.54-35.37-12-.79a109.81,109.81,0,0,1-32.43-8.66c-16.28-7-38.46-21.2-51.66-49l-1.07-2.23-74.36,18.39,1.31,3.24C504.66,560.93,510.92,576.17,525.34,593Zm48.79-47.29c14.13,28,36.86,42.49,53.65,49.73a124.61,124.61,0,0,0,24.1,7.59L596.29,628.4c-32-6-53.66-24.55-66.21-39.17a135.14,135.14,0,0,1-18.59-28Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<rect :class="preset + '-logo&#45;&#45;icon-a'" x="32.31" y="48.02" width="20.09" height="20.09"/>-->
        <!--<rect :class="preset + '-logo&#45;&#45;icon-b'" x="15.2" y="61.34" width="11.02" height="11.02"/>-->
        <!--<rect :class="preset + '-logo&#45;&#45;icon-a'" y="32.38" width="8.76" height="8.76"/>-->
        <!--<rect :class="preset + '-logo&#45;&#45;icon-b'" x="17.53" y="14.67" width="13.15" height="13.15"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;icon-b'" d="M663.72,615.37s-3.67,0-9.72-.16l-58.46,26.68-.95-.14c-33.45-4.33-57.31-25.66-72.31-44.54l-1.36.62-8,3.6v8.63H493.73l-14.12,6.35s22.58,16.75,56.65,24.66h0a234.26,234.26,0,0,0,59,7Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M742.65,592.31l-8.54,10.39c-4.46-5.1-10.86-8.45-16.89-8.45a20,20,0,0,0,0,40c5.85,0,12.25-3,16.89-7.61l8.63,9.37c-6.87,7.06-16.8,11.6-26.26,11.6-19.4,0-34.06-14.38-34.06-33.22s14.94-32.85,34.61-32.85A37,37,0,0,1,742.65,592.31Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M765.47,606c3.44-6.12,9.1-9.47,16.52-9.56v13.46c-10.21-.93-16.52,4.54-16.52,13v24H751.18V597h14.29Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M839,647h-14.2v-7.14c-3.53,4.92-8.91,7.7-15.68,7.7-14.2,0-23.57-10.3-23.57-25.7,0-15.13,9.18-25.25,23.1-25.34,7,0,12.53,2.79,16.15,7.8V597H839Zm-14.2-23.1v-3.34c-.74-7.24-5.57-12.16-12.34-12.16-7.43,0-12.53,5.75-12.62,13.83.09,8.16,5.1,13.82,12.62,13.82C819.2,636,824,631.09,824.77,623.86Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M869.68,595.09v4.18h11.79v9.93H869.68V647h-14.2V609.2H848.8v-9.93h6.68v-4.74c0-11.13,8.36-17.07,18.38-17.07A24.63,24.63,0,0,1,886.94,581l-5.1,10.39a10.75,10.75,0,0,0-6.22-2.14C872.28,589.24,869.68,591.1,869.68,595.09Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M919.42,644.18a26.62,26.62,0,0,1-13,3.43c-8.91,0-15.68-5.1-15.68-15.4V609.1h-7v-9.83h7V585.53h14.19v13.74h13.55v9.93H904.94v20.6c0,4.17,1.77,5.84,4.83,5.75a16.59,16.59,0,0,0,6.68-1.86Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M957.28,582c18.93,0,33.41,14,33.41,32.47S976.12,647,957,647H931V582Zm-18.84,57.9h18.93c14.48,0,25.8-10.86,25.8-25.33s-11.51-25.43-26.08-25.43H938.44Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M1047.85,622.46c0,14.67-10.3,24.78-24.78,24.78s-24.78-10.11-24.78-24.78,10.3-24.59,24.78-24.59S1047.85,607.9,1047.85,622.46Zm-42.32.1c0,10.76,7.33,18.19,17.54,18.19s17.54-7.43,17.54-18.19-7.33-18.1-17.54-18.1S1005.53,611.89,1005.53,622.56Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M1098.7,605.11l-4,5.11a20.62,20.62,0,0,0-14.57-5.67c-10.11,0-17.35,7.34-17.35,18s7.24,18.09,17.35,18.09c6.4,0,11.69-2.22,15.13-6.31l4.17,4.46c-4.36,5.29-11.13,8.44-19.58,8.44-14.2,0-24.22-10.11-24.22-24.68s10.12-24.69,24.22-24.69C1087.75,597.78,1094.25,600.47,1098.7,605.11Z" transform="translate(-457.22 -538.66)"/>-->
        <!--<path :class="preset + '-logo&#45;&#45;name'" d="M1141.2,602.24l-2.78,5.75a25.89,25.89,0,0,0-13.27-4c-5.48,0-9.65,2-9.65,6.49,0,10.86,27.28,5.29,27.28,22.83,0,9.65-8.54,13.92-17.91,13.92-7,0-14.11-2.41-18.75-6.87l2.79-5.47a24.73,24.73,0,0,0,16.24,6.22c5.75,0,10.39-2.14,10.48-7,.28-11.79-27.09-5.76-27-23.11,0-9.37,8.07-13.27,17-13.27A28.2,28.2,0,0,1,1141.2,602.24Z" transform="translate(-457.22 -538.66)"/>-->
    <!--</svg>-->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.27 139.93">
        <g>
            <g>
                <path class="a" d="M229.68,296.54l-8.1-.38-2.91-.12a9.37,9.37,0,0,1-2.4-.38,3.27,3.27,0,0,1-1-.38,8,8,0,0,1-1.14-.25q-13.55-3.42-20.75-16.07a46.24,46.24,0,0,1-6.19-23.27,94.56,94.56,0,0,1,2-21.25A40.33,40.33,0,0,1,196,219.13a29,29,0,0,1,13.91-10.37,62,62,0,0,1,9.74-2.4q5.31-.77,42.12-.76v15.56H230.43q-9.1,0-14.54,5.06A24.06,24.06,0,0,0,209.82,238a71.12,71.12,0,0,0-1.77,16.07,46.48,46.48,0,0,0,2.27,15.43Q213.61,278,221,280l2,.5,2.28.13,36.55.12v15.81Z" transform="translate(-187.18 -205.6)"/>
                <path class="a" d="M350.09,296.54,330,261H307.59v35.54h-19.1V205.6H340.1l3.54.63,2.65.38a6.71,6.71,0,0,1,1.65.13c.42.08,1.43.34,3,.76q8,1.89,12.4,9.74a32.73,32.73,0,0,1,3.92,16.31,27.86,27.86,0,0,1-4.43,15.94q-4.17,6.45-13.78,9l22.39,38.07ZM345,225q-3.54-3-14-3h-23.4v23H331q10.36,0,13.79-3a11.36,11.36,0,0,0,3.29-8.6C348.07,229.51,347.05,226.72,345,225Z" transform="translate(-187.18 -205.6)"/>
                <path class="a" d="M459.75,296.54l-8.34-21.75h-39.6L404,296.54H383.73l38.2-90.94h20.24L481,296.54Zm-28.33-72.6L418,258.73h27.2Z" transform="translate(-187.18 -205.6)"/>
                <path class="a" d="M518.7,259.23v37.31H499.34V254.8q0-19.35,3.16-28.45A26.86,26.86,0,0,1,516,210.53q9.74-4.92,57.17-4.93v15.81H542.35q-12.4,0-17.46,5.06a20.4,20.4,0,0,0-4.55,7.08,28.92,28.92,0,0,0-1.64,10.12h54.51v15.56Z" transform="translate(-187.18 -205.6)"/>
                <path class="a" d="M638.1,221.66v74.88H618.24V221.66H589.15V205.85h78.29v15.81Z" transform="translate(-187.18 -205.6)"/>
            </g>
            <g>
                <path class="b" d="M318.82,343.07q-2.46,1.86-23.54,1.86v-5.2h15.24a7.35,7.35,0,0,0,3.22-.53,2.28,2.28,0,0,0,1.49-2,8.85,8.85,0,0,0-.12-1.81,1.54,1.54,0,0,0-.24-.73,1.4,1.4,0,0,0-.32-.52q-1.05-1-5.93-1.05h-1.37l-2,0a19.93,19.93,0,0,1-6.65-.81q-4.55-1.77-4.55-7t2.82-7.46c1.67-1.26,4.11-1.89,7.34-1.89h16v5.2h-15a6.26,6.26,0,0,0-3.46.84,3.12,3.12,0,0,0-1.29,2.7,3.09,3.09,0,0,0,1.09,2.62q1.13.76,10.8.77l2.3,0,1,.12.85.16a6.11,6.11,0,0,1,1.85.73l.69.52.89.73a4.75,4.75,0,0,1,1.37,2.58,21.21,21.21,0,0,1,.4,4.75A6.4,6.4,0,0,1,318.82,343.07Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M353.77,341.74a11.07,11.07,0,0,1-4.48,2.83,19.43,19.43,0,0,1-6.49,1q-7.33,0-10.92-3.83t-3.55-11.32q0-7.38,3.55-11.17t9.83-3.83c4.2,0,7.38.61,9.56,1.82a10.9,10.9,0,0,1,5.16,6.81,28.3,28.3,0,0,1,.68,3.71,44.3,44.3,0,0,1,.24,4.83A13,13,0,0,1,353.77,341.74Zm-4.68-18.3c-1.32-2-3.41-3.06-6.29-3.06a7,7,0,0,0-6.17,3,10.74,10.74,0,0,0-1.69,6.05,23.49,23.49,0,0,0,.77,6.37,7.87,7.87,0,0,0,1.05,2.38,4.82,4.82,0,0,0,2.09,1.57l2,.56a20.54,20.54,0,0,0,3.71.25q2.42,0,4.47-3a13.11,13.11,0,0,0,1.65-7.05Q350.7,325.9,349.09,323.44Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M371.34,333v11.89h-6.17v-13.3a29.83,29.83,0,0,1,1-9.07,8.58,8.58,0,0,1,4.31-5q3.11-1.57,18.22-1.57v5h-9.83c-2.64,0-4.49.53-5.56,1.61a6.58,6.58,0,0,0-1.46,2.25,9.51,9.51,0,0,0-.52,3.23h17.37v5Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M409.39,321.07v23.86h-6.33V321.07h-9.27v-5h24.95v5Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M458.61,344.93h-7.5L445,323.36l-5.44,21.57h-7.42l-9.59-29H429l6.69,22.29L441.36,316h7.17l6.53,22.29L461.27,316h6.21Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M491.54,344.93,488.88,338H476.26l-2.5,6.93h-6.44l12.17-29h6.45l12.37,29Zm-9-23.14-4.27,11.09h8.67Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M524,344.93l-6.41-11.33h-7.13v11.33H504.4V316h16.45l1.12.2.85.12a2,2,0,0,1,.53,0l1,.24a6,6,0,0,1,4,3.11,10.31,10.31,0,0,1,1.25,5.19,8.87,8.87,0,0,1-1.41,5.08,7.14,7.14,0,0,1-4.39,2.87l7.13,12.13Zm-1.61-22.82c-.75-.64-2.25-1-4.48-1h-7.45v7.33h7.45c2.21,0,3.67-.32,4.4-1a3.61,3.61,0,0,0,1-2.74A3.38,3.38,0,0,0,522.42,322.11Z" transform="translate(-187.18 -205.6)"/>
                <path class="b" d="M550.31,344.93l-2.58-.12-.93,0a3,3,0,0,1-.76-.12,1.5,1.5,0,0,1-.32-.12l-.37-.08a10.31,10.31,0,0,1-6.61-5.12,13.12,13.12,0,0,1-1.45-3.55,14,14,0,0,1-.52-3.91,29.94,29.94,0,0,1,.64-6.73,12.88,12.88,0,0,1,2.18-4.88A9.23,9.23,0,0,1,544,317a20.14,20.14,0,0,1,3.11-.76q1.77-.24,13.42-.24v5h-10q-6.85,0-6.85,6.77h16.85v5H543.7a12.53,12.53,0,0,0,.28,2.9,5.32,5.32,0,0,0,.37,1.21,4.57,4.57,0,0,0,.68,1l1.09,1a4.11,4.11,0,0,0,1.29.65,5.24,5.24,0,0,0,1.49.24l11.65,0v5Z" transform="translate(-187.18 -205.6)"/>
            </g>
            <rect class="b" x="382.27" y="122.42" width="97.99" height="4.86"/>
            <rect class="b" y="122.42" width="97.99" height="4.86"/>
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            preset: {
                default: 'logo',
                type: String
            }
        }
    }
</script>

<style scoped>
    .a {
        fill: #4f9ce8;
    }

    .b {
        fill: #666;
    }

    svg {
        margin-top: 20px;
        width: 150px;
        margin-right: 30px;
    }
</style>