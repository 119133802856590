<template>
    <section>
        <!-- Header -->
        <nav class="navbar navbar-expand-lg navbar-light bg-light bg-transparent" id="gtco-main-nav">
            <div class="container">
                <a class="navbar-logo">
                    <logo></logo>
                </a>
                <button class="navbar-toggler"
                        :class="{'change': toggled}"
                        data-target="#my-nav"
                        @click="toggleMenu"
                        data-toggle="collapse">
                    <span class="bar1"></span>
                    <span class="bar2"></span>
                    <span class="bar3"></span>
                </button>
                <div id="my-nav" class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
                        <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- Banner -->
        <div class="container-fluid gtco-banner-area">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1>We help companies turn great ideas
                            into <span>amazing</span> apps, products, and services.
                        </h1>
                        <p>Craft Software offers America’s best software engineers, on demand, at very competitive rates.</p>
                        <a href="#contact">
                            Contact Us
                            <span class="icon">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </span>
                        </a>
                    </div>
                    <div class="col-md-6">
                        <div class="card"><img class="card-img-top img-fluid" src="/images/banner-img.png" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Logo from '../../components/misc/Logo'

    export default {
        data() {
            return {
                toggled: false,
            }
        },
        methods: {
            toggleMenu() {
                this.toggled = !this.toggled
            }
        },
        components: {
            Logo
        }
    }
</script>