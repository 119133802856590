<template>
    <footer class="container-fluid" id="gtco-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <contact></contact>
                </div>
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12">
                            <p>Copyright © {{ year }} <strong>Craft Software, LLC</strong>. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Contact from './Contact'

    export default {
        data() {
            return {
                year: (new Date()).getFullYear()
            }
        },
        computed: {

        },
        methods: {

        },
        components: {
            Contact
        }
    }
</script>